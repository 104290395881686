body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* ------------------ DATA TABLE ------------------ */

tr:nth-child(even) {
  background: #dceff6;
}
tr:nth-child(odd) {
  background: #fcfcfc;
}
td {
  text-align: center;
  padding: 20px;
}
.desc {
  text-align: left;
}
.rbc-event {
  padding: 0 !important;
  background-color: transparent !important;
}
